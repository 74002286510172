





























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, Global, Projects } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import * as am4core from '@amcharts/amcharts4/core';
am4core.addLicense('CH351911686');
import * as am4charts from '@amcharts/amcharts4/charts';

const colors = [
  '#91e15d',
  '#ee5b5b',
  '#5598fb',
  '#b469df',
  '#5fb1a9',
  '#60c6e1',
  '#FFFF00',
  '#004753',
  '#F17925',
  '#70b0E0',
  '#8AD4EB',
  '#D8D7BF',
  '#5B2071',
  '#224624',
  '#C4B07B',
  '#F64F5C'
];

@Component({})
export default class FullViewReportPie extends Vue {
  @Prop() props;
  @Prop() type;
  @Prop() overall;
  @Prop() power;
  @Prop() screw;
  @Prop() painted;
  @Prop() pole;
  @Prop() alerts;
  @Prop() events;
  @Prop() isLoading;

  @Projects.State('list') projects;

  @Reports.State('reportsList') projectDevices;
  @Global.State('lang') lang;

  chart: am4charts.PieChart = null;

  mounted() {
    this.setCharts();
  }

  setColors(data) {
    if (this.type === 'alerts' || this.type === 'events'){
      data[0].color = am4core.color('#ee5b5b');
      data[1].color = am4core.color('#FF8C00');
      data[2].color = am4core.color('#91e15d');
    }else {
      data.forEach((value, index) => value.color = am4core.color(colors[index]));
    }
  }

  @Watch('isLoading')
  @Watch('$store.state.Global.lang')
  @Watch('props')
  @Watch('overall')
  @Watch('alerts')
  @Watch('events')
  @Watch('power')
  @Watch('screw')
  @Watch('painted')
  @Watch('pole')
  setCharts() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    if (this.overall) {
      this.setOverallChart();
    } else if (this.props) {
      this.setChart();
    } else if (this.power) {
      this.setPowerChart();
    } else if (this.screw) {
      this.setScrewChart();
    } else if (this.painted) {
      this.setPaintedChart();
    } else if (this.pole) {
      this.setPolesChart();
    } else if (this.alerts) {
      this.setAlertsChart();
    } else if (this.events) {
      this.setEventsChart();
    }
  }

  setChart() {
    const chart = am4core.create('chartdiv', am4charts.PieChart);
    chart.data = cloneDeep(this.props);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    chart.legend.maxWidth = 170;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';

    chart.legend.valueLabels.template.properties.scale = 1.3;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text = this.$store.state.Global.lang === 'en' ? 'Total devices' : 'סה"כ בקרים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    chart.legend.maxWidth = 200;
    this.chart = chart;
  }

  setOverallChart() {
    const chart = am4core.create('chartdiv1', am4charts.PieChart);
    chart.data = cloneDeep(this.overall);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total devices' : 'סה"כ בקרים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    chart.legend.maxWidth = undefined;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }
  
  setAlertsChart() {
    const chart = am4core.create('chartdiv2', am4charts.PieChart);
    chart.data = cloneDeep(this.alerts);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total alerts' : 'סה"כ התרעות';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    chart.legend.maxWidth = undefined;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }

  setEventsChart() {
    const chart = am4core.create('chartdiv3', am4charts.PieChart);
    chart.data = cloneDeep(this.events);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total events' : 'סה"כ אירועים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    chart.legend.maxWidth = undefined;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }

  setPowerChart() {
    const chart = am4core.create('chartdiv4', am4charts.PieChart);
    chart.data = cloneDeep(this.power);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total powered' : 'סה"כ מותקנים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    chart.legend.maxWidth = undefined;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }

  setScrewChart() {
    const chart = am4core.create('chartdiv5', am4charts.PieChart);
    chart.data = cloneDeep(this.screw);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = `[bold]{value}[/bold]`;
    chart.legend.valueLabels.template.properties.scale = 1.3;
    chart.legend.maxWidth = undefined;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total screws' : 'סה"כ ברגים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }
  
  setPaintedChart() {
    const chart = am4core.create('chartdiv6', am4charts.PieChart);
    chart.data = cloneDeep(this.painted);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;

    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    chart.legend.maxWidth = undefined;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total painted' : 'סה"כ צבועים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }
  
  setPolesChart() {
    const chart = am4core.create('chartdiv7', am4charts.PieChart);
    chart.data = cloneDeep(this.pole);
    this.setColors(chart.data);
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'place';
    pieSeries.labels.template.text = '';
    chart.innerRadius = am4core.percent(70);
    chart.legend = new am4charts.Legend();
    chart.legend.properties.scale = 1;
    pieSeries.slices.template.stroke = am4core.color('white');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.propertyFields.fill = 'color';
    chart.legend.markers.template.width = 16;
    chart.legend.markers.template.height = 16;
    chart.legend.width = 500;
    chart.legend.align = 'left';
    chart.legend.position = 'left';
    chart.legend.valueLabels.template.text = '[bold]{value}[/bold]';
    chart.legend.valueLabels.template.properties.scale = 1.3;
    chart.legend.maxWidth = undefined;
    const label = pieSeries.createChild(am4core.Label);
    label.text = '[bold]{values.value.sum}[/bold]';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.fontSize = 24;
    const label2 = pieSeries.createChild(am4core.Label);
    label2.text =
      this.$store.state.Global.lang === 'en' ? 'Total poles' : 'סה"כ עמודים';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'top';
    label2.fontSize = 16;
    const hs = pieSeries.slices.template.states.getKey('hover');
    hs.properties.scale = 1;
    this.chart = chart;
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }
}
