import { render, staticRenderFns } from "./FullViewReportPie.vue?vue&type=template&id=21ca0ffa&scoped=true"
import script from "./FullViewReportPie.vue?vue&type=script&lang=ts"
export * from "./FullViewReportPie.vue?vue&type=script&lang=ts"
import style0 from "./FullViewReportPie.vue?vue&type=style&index=0&id=21ca0ffa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ca0ffa",
  null
  
)

export default component.exports